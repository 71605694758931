<template>
  <div v-loading="loading">
    <el-select
      v-model="selected_filter"
      @change="updateEntityData"
      clearable
      filterable
      :placeholder="fieldPlaceholder"
      :size="size == 'mini' ? 'mini' : 'large'"
      collapse-tags
      :multiple="isMultiple ? true : false"
    >
      <el-option
        v-for="(temp, i) in allEntityDataList"
        :key="temp._id + '_' + i"
        :value="temp._id"
        :label="getLabel(temp, i)"
        >{{ getLabel(temp, i) }}
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { postAPICall } from "@/helpers/httpHelper";

export default {
  props: [
    "fieldPlaceholder",
    "size",
    "isMultiple",
    "filter",
    "allMultiFilters",
    "filterValues",
  ],
  computed: {
    ...mapGetters("entities", [
      "getEntityDataByEntityId",
      "getEntityById",
      "getEntityRecordsForTable",
      "getEntitiesDataErrors",
    ]),
  },
  watch: {
    async field() {
      this.fetchEntityById(this.field.filter_field_entity_id);
    },
    filterValues: {
      handler() {
        if (
          this.filter?.multiple_filter_dependency &&
          this.filterValues[this.filter.multiple_filter_dependency] !=
            this.previousValue
        ) {
          this.previousValue =
            this.filterValues[this.filter.multiple_filter_dependency];
          if (this.previousData[this.previousValue]) {
            this.allEntityDataList = this.previousData[this.previousValue];
          } else {
            this.fetchEntityById(this.filter.filter_field_entity_id);
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      currentEntity: null,
      loading: false,
      allEntityDataList: [],
      field: [],
      selected_filter: [],
      previousValue: "",
      previousData: {},
    };
  },
  async mounted() {
    this.loading = true;
    await this.fetchEntityById(this.filter.filter_field_entity_id);
    this.loading = false;
  },
  methods: {
    updateEntityData(value) {
      this.selectedValue = value;
      this.filter.selected_filter = value;
      this.$emit("mulEntityFilterUpdated", {
        value: value,
        filter: this.filter,
      });
    },
    async fetchEntityById(entityId) {
      // await this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
      //   entity_id: entityId,
      // });
      // if (this.getEntityById) {
      //   this.currentEntity = this.getEntityById;
      //   this.fetchEntitiesDataForTable(entityId);
      // } else {
      //   this.loading = false;
      // }
      this.fetchEntitiesDataForTable(entityId);
    },
    buildFilters() {
      let filters = [];
      if (this.filter?.multiple_filter_dependency) {
        let parentFilter = this.allMultiFilters.find(
          (e) => e.keyValue == this.filter.multiple_filter_dependency
        );
        filters.push({
          field: "self#related_to/1",
          operator: "=",
          value:
            this.filterValues[this.filter.multiple_filter_dependency] || "",
          value_type: "",
          value_source: "",
          data_type: "RELATION",
          query_type: "OR",
          value_field: this.filter.multiple_filter_dependency,
          validations: {
            data_type: "",
          },
          nested_related_id: "",
          relation_entity_id: parentFilter.filter_field_entity_id || "",
          input_type: "RELATION",
          data_source: "self_field",
        });
      }
      return filters;
    },
    async fetchEntitiesDataForTable(entityId) {
      this.loading = true;
      let params = {
        entity_id: entityId,
        template_fields_data: [],
        filters: this.buildFilters(),
      };
      // await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      const getEntityRecordsForTable = await postAPICall(
        "POST",
        "/entities-data/entity/data",
        params
      );
      if (getEntityRecordsForTable) {
        this.allEntityDataList = getEntityRecordsForTable.data;
        this.currentEntity = getEntityRecordsForTable.selectedEntity;
        if (
          this.filter.multiple_filter_dependency &&
          this.filterValues[this.filter.multiple_filter_dependency]
        ) {
          this.$set(
            this.previousData,
            this.filterValues[this.filter.multiple_filter_dependency],
            this.allEntityDataList
          );
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    getLabel(item, i) {
      let selectedEntity = this.currentEntity ? this.currentEntity : {};
      let name = "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        if (
          item?.entityData?.[primaryField?.template_id]?.[primaryField?.key]
        ) {
          name =
            primaryField.inputType == "ENTITY"
              ? item.entityData[primaryField.template_id][
                  primaryField.key + "/name"
                ]
              : primaryField.input_type == "DATE"
              ? moment(
                  item.entityData[primaryField.template_id][primaryField.key]
                ).format(this.getDefaultDateFormat)
              : primaryField.input_type == "DATE_TIME"
              ? moment(
                  item.entityData[primaryField.template_id][primaryField.key]
                ).format(this.getDefaultDateFormat + " HH:mm:ss")
              : item.entityData[primaryField.template_id][primaryField.key];
        }
      }
      return name;
    },
    getRandomKey() {
      return (Math.random() + 1).toString(36).substring(7);
    },
  },
};
</script>
